<template>
  <div>
    <b-card>
      <h5>
        상품 현황
        <small class="ml-1 text-muted">{{ goodsCnt._dt }} 시점의 데이터입니다.
          <!--갱신에는 1~2분정도 소요됩니다. <i class="fa fa-refresh" @click="refreshStat(true)"></i>-->
        </small>
      </h5>
      <div v-if="busy.stat" class="text-center">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <b-row v-else>
        <b-col>
          Hub DB <br/>
          <b-badge variant="dark">total</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.total) }}</span><br/>
          <b-badge variant="warning">processing</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.processing) }}</span><br/>
          <b-badge variant="success">registered</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.registered) }}</span><br/>
          &nbsp; &nbsp;<b-badge variant="success">normal</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.normal) }}</span><br/>
          &nbsp; &nbsp;<b-badge variant="warning">runout</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.runout) }}</span><br/>
          &nbsp; &nbsp;<b-badge variant="light">notview</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.notview) }}</span><br/>
          <b-badge variant="danger">terminated</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.terminated) }}</span><br/>
          <b-badge variant="light">trash</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.hub.trash) }}</span><br/>
        </b-col>
        <b-col>
          발란몰 <br/>
          <b-badge variant="dark">total</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.godo.total) }}</span><br/>
          <b-badge variant="success">open</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.godo.open) }}</span><br/>
          <b-badge variant="warning">closed</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.godo.closed) }}</span><br/>
        </b-col>
        <b-col>
          롯데아이몰 <br/>
          <b-badge variant="dark">total</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.imall.total) }}</span><br/>
          <b-badge variant="success">open</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.imall.open) }}</span><br/>
          <b-badge variant="warning">closed</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.imall.closed) }}</span><br/>
          <b-badge variant="danger">terminated</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.imall.terminated) }}</span><br/>
        </b-col>
        <b-col>
          SmartStore <br/>
          <b-badge variant="dark">total</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.ss.total) }}</span><br/>
          <b-badge variant="success">open</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.ss.open) }}</span><br/>
          <b-badge variant="warning">closed</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.ss.closed) }}</span><br/>
        </b-col>
        <b-col>
          Mapped <br/>
          <b-badge variant="dark">total</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.mapped.total) }}</span><br/>
          <b-badge variant="success">live</b-badge>&nbsp;<span>{{ utils.comma(goodsCnt.mapped.live) }}</span><br/>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs v-model="tabIndex">
      <b-tab title="신규상품 등록">
        <b-row>
          <b-col lg="8" ref="shopArea">
            <b-card>
              <b-input-group class="mb-1">
                <b-input-group-prepend>
                  <b-button variant="primary" @click="getNewGoodsId" :disabled="busy.shops">
                    <i class="fa fa-search"></i> 검색
                    <b-spinner class="ml-1" small v-if="busy.shops"></b-spinner>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input type="text" placeholder="goods_no, goods_id, sku_id 을 넣어주세요" v-model="formShop.search"
                              @keypress.enter.prevent.stop="getNewGoodsId" v-focus></b-form-input>
                <!--<b-input-group-append>
                  <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
                </b-input-group-append>-->
              </b-input-group>
              <shop-preset class="mb-2" v-model="formShop.shop"></shop-preset>
              <b-row>
                <b-col>
                  <brand-preset class="mb-2" v-model="formShop.brand" :hideDisabled="true"></brand-preset>
                </b-col>
                <b-col>
                  <category-preset class="mb-2" v-model="formShop.category"></category-preset>
                </b-col>
              </b-row>
              <small>goods_id</small>
              <b-form-textarea :rows="2" v-model="formShop.goods_id" placeholder="goods_id를 입력해주세요"></b-form-textarea>
              <small class="text-muted">엔터로 구분된 goods_id를 입력해주세요</small><br/>
              <b-button class="mt-1 mr-1" variant="primary" @click="getNewGoodsId" :disabled="busy.shops">검색
                <b-spinner class="ml-1" small v-if="busy.shops"></b-spinner>
              </b-button>
              <hr/>
              <b-input-group>
                <b-input-group-prepend>
                  <b-button variant="primary" @click="filterShop">
                    <i class="fa fa-filter"></i> 필터링
                  </b-button>
                </b-input-group-prepend>
                <b-form-input type="text" v-model="formShop.shopFilter" placeholder="shop id, name을 넣어주세요" @keypress.enter="filterShop" v-focus></b-form-input>
              </b-input-group>
              <b-form-radio-group class="col-form-label" v-model="formShop.shopType" @change="filterShopType" :options="[
                {text: '전체', value: 'ALL'},
                {text: '부티크', value: 'boutique'},
                {text: '편집샵', value: 'edit_shop'},
                {text: '병행', value: 'parallel'},
                {text: '브랜드', value: 'brand'},
                {text: '기타', value: 'etc'},
              ]">
              </b-form-radio-group>
              <b-button class="mt-1 mr-1" size="sm" variant="primary" @click="selectAllShop">전체 선택/해제</b-button>
              <div class="pull-right">
                <b-button v-if="$R('DEV')" class="mt-1 mr-1" size="sm" variant="light" @click="imageNotReady">이미지 미처리 통계</b-button>
                <b-button class="mt-1 mr-1" size="sm" variant="light" @click="copyGoodsId">GoodsId 복사</b-button>
                <b-button class="mt-1 mr-1" size="sm" variant="danger" @click="processAllGoods">전체상품 생성</b-button>
                <b-button class="mt-1 mr-1" size="sm" variant="warning" @click="processSellableGoods">판매가능상품 생성</b-button>
                <b-button class="mt-1 mr-1" size="sm" variant="success" @click="processSendableGoods">등록가능상품 생성</b-button>
                <b-button class="mt-1 mr-1" size="sm" variant="primary" @click="processPerfectGoods">완전매칭상품 생성</b-button>
              </div>
              <div class="mb-1"></div>
              <hot-table ref="hotTableShops" :settings="hotSettingsShops"></hot-table>
              <small>
                * SHOP의 use_yn 이 'y' 인 경우만 보입니다.<br/>
                * 재고가 있고 이미지가 1개 이상일 경우 판매가능상품이 됩니다<br/>
                * 재고가 있고 category, brand 가 매칭되었고 이미지가 1개 이상일 경우 등록가능상품이 됩니다<br/>
                * 재고가 있고 category, brand 가 매칭되었고 이미지가 1개 이상이며 상품명이 생성되었을 때 일 경우 완전매칭상품이 됩니다
              </small>

              <div class="title-sm clearfix">
                상품등록 이력 <a href="/#/data/store/65?mode=view" target="_blank">(실패사유 및 상품 확인)</a>
                <div class="float-right"><span>성공한 상품이 없는 이력 포함</span>
                  <c-switch v-model="processLogWithError" style="vertical-align:middle;margin-top:-5px" color="success" label variant="pill"/>
                </div>
              </div>
              <div class="d-flex justify-content-center mb-2">
                <b-form inline>
                  <b-button class="mr-1" @click="setProcessLogDay(-1)"><i class="fa fa-caret-left"></i></b-button>
                  <date-input v-model="formProcessLog._d" @change="v=>{setProcessLogDay(v)}" @enter="processLog()"></date-input>
                  <b-button class="ml-1" @click="setProcessLogDay(1)"><i class="fa fa-caret-right"></i></b-button>
                </b-form>
              </div>
              <div v-if="busy.processLog" class="text-center py-4">
                <b-spinner></b-spinner>
              </div>
              <template v-else>
                <div v-for="([name, arr], idx) in Object.entries($utils.arr2multi(items.processLog.filter(e => e.inserted || processLogWithError), '_name'))"
                     :key="name">
                  <span><b>{{ name }}</b></span>
                  <b-row>
                    <b-col cols="3" v-for="e in arr" class="py-1">
                      <span class="badge alert-primary mr-1">{{ e._dt.split(' ')[1] }}</span>
                      <b-badge variant="success" class="alert-success" target="_blank"
                               :href="`/#/goods?$shop=${e.shop_ids.join(',')}&goods_status=ALL&display_status=ALL&stock_status=ALL&created_from=${e._dt}&created_to=${e._dt}&limit=${Math.min(9999, e.limit)}`">
                        SHOP {{ e.shop_ids.slice(0, 4).join(', ') }}{{ e.shop_ids.length > 4 ? ` 외 ${e.shop_ids.length - 4} 샵` : '' }}
                      </b-badge>
                      <br/>
                      <span v-if="e.ok">
                        성공 : <b-badge variant="success" class="pointer" @click="searchProcessing(e.goods_nos)">{{ e.inserted }}</b-badge>
                        <span v-if="e.errCnt"> 실패 : <b-badge variant="warning">{{ e.errCnt }}</b-badge> <i class="fa fa-exclamation-circle"
                                                                                                           :title="processLogFailDetail(e.err)"></i></span>
                      </span>
                      <span v-else>
                        실패: {{ e.limit }} <i class="fa fa-exclamation-circle" :title="processLogFailDetail(e.err)"></i>
                      </span>
                    </b-col>
                  </b-row>
                  <hr/>
                </div>
                <div v-if="items.processLog.length === 0" class="text-center py-3">
                  상품등록 이력이 없습니다.
                </div>
              </template>
            </b-card>
          </b-col>
          <b-col cols="4" ref="unmatchedArea">
            <template v-if="!busy.getUnmatchedMeta && unmatchedListItem.shop_id" v-for="e of [unmatchedListItem]">
              <div class="clearfix">
                <div class="pull-right">
                  <b-button variant="warning" size="sm" @click="copyTsv('brands')">브랜드({{ e.brands.length }} 개) 복사</b-button>
                  <b-button variant="light" size="sm" @click="copyTsv('cates')">카테고리({{ e.cates.length }} 개) 복사</b-button>
                </div>
                <h4>{{ e.shop_id }}. {{ e.shop_name }}</h4>
              </div>
              <table class="text-center table table-bordered table-sm fs-12">
                <tr>
                  <th>미매칭 브랜드</th>
                  <th style="width:50px">상품수</th>
                </tr>
                <template v-if="e.brands.length">
                  <tr v-for="b of e.brands">
                    <td style="padding: 0.1rem 0.3rem;">{{ b.brand }}</td>
                    <td style="padding: 0.1rem 0.3rem;">{{ b.cnt }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td style="padding: 0.1rem 0.3rem;" colspan="2">미매칭 브랜드가 없습니다</td>
                  </tr>
                </template>
              </table>
              <table class="text-center table table-bordered table-sm fs-12">
                <tr>
                  <th style="width:50px">2차</th>
                  <th>미매칭 카테고리</th>
                  <th style="width:50px">상품수</th>
                </tr>
                <template v-if="e.cates.length">
                  <tr v-for="c of e.cates">
                    <td style="padding: 0.1rem 0.3rem;">{{ c.category }}</td>
                    <td style="padding: 0.1rem 0.3rem;">{{ c.origin_category }}</td>
                    <td style="padding: 0.1rem 0.3rem;">{{ c.cnt }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td style="padding: 0.1rem 0.3rem;" colspan="3">미매칭 카테고리가 없습니다</td>
                  </tr>
                </template>
              </table>
            </template>
            <div v-if="busy.getUnmatchedMeta" class="text-center pt-5">
              <b-spinner size="xl"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <b-card id="formProcessing">
          <b-row>
            <b-col :lg="processingDetail ? 8 : 12">
              <shop-preset class="mb-2" v-model="formProcessing.shop"></shop-preset>

              <b-collapse id="collapseProcessing" v-model="collapse.processing">
                <b-row class="mb-3">
                  <b-col cols="12" lg="6">
                    <brand-preset class="mb-2" v-model="formProcessing.brand" :hideDisabled="true"></brand-preset>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <category-preset class="mb-2" v-model="formProcessing.category"></category-preset>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col lg="4">
                    <small>한 번에 가져올 상품 수</small><br/>
                    <b-form-input type="text" placeholder="한 번에 가져올 상품 수" v-model.number="formProcessing.limit"></b-form-input>
                  </b-col>
                  <b-col lg="4">
                    <small>카테고리 제한</small><br/>
                    <b-checkbox v-model="formProcessing.cateOver2nd">1, 2차 카테고리는 제외합니다</b-checkbox>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col>
                    <small>goods_no</small><br/>
                    <b-form-textarea :rows="2" v-model="formProcessing.goods_no" placeholder="goods_no를 입력해주세요"></b-form-textarea>
                  </b-col>
                </b-row>
              </b-collapse>
              <div class="clearfix">
                <b-button class="mr-1" variant="primary" @click="listProcessing" :disabled="busy.processing">
                  <b-spinner class="mr-2" small v-if="busy.processing"></b-spinner>
                  검색
                </b-button>
                <b-button class="" variant="outline-success" v-b-toggle.collapseProcessing>상세검색조건</b-button>
                <div class="pull-right">
                  <b-button :variant="`${processingDetail ? 'outline-' : ''}info`" @click="processingDetail = !processingDetail">상세정보</b-button>
                </div>
              </div>
              <hr/>

              <div class="clearfix mb-1">
                <b-button class="mr-1" size="sm" variant="dark" @click="modal.processingField = true"><i class="fa fa-pencil"></i></b-button>
                <span>{{ items.processing.filter(e => e.selected).length }}/{{ items.processing.length }}</span>&nbsp;
                <b-button class="mr-1" size="sm" variant="primary" @click="selectAllGoods('processing')">전체 선택/해제</b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="saveProcessing('all')" :disabled="busy.saveProcessing">
                  <b-spinner class="mr-2" small v-if="busy.saveProcessing"></b-spinner>
                  전체변경저장
                </b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="saveProcessing()" :disabled="busy.saveProcessing">
                  <b-spinner class="mr-2" small v-if="busy.saveProcessing"></b-spinner>
                  저장
                </b-button>
                <span>변경된 상품수 : {{ changed.processing }}</span>
                <b-button class="ml-1 mr-1" size="sm" variant="warning" @click="undoProcessing()">되돌리기</b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="down(items.processing, 'xlsx', 'Processing')">xlsx Down</b-button>
                <b-button class="mr-1" size="sm" variant="outline-success" @click="_=>{$refs.xlsxProcessing.value = null;$refs.xlsxProcessing.click()}">xlsx
                  Up
                </b-button>
                <input type="file" ref="xlsxProcessing" data-type="processing" style="display: none" @change="handleXlsx">

                <div class="pull-right">
                  <b-button class="mr-1" size="sm" variant="success" @click="openPopup">NaverSKU창</b-button>
                  <b-button class="mr-1" size="sm" variant="info" @click="imageProcessingModal(items.processing)" :disabled="busy.renewImage">
                    <b-spinner class="mr-2" small v-if="busy.renewImage"></b-spinner>
                    이미지 재처리
                  </b-button>
                  <b-button class="mr-1" size="sm" variant="info" @click="renewPrice(items.processing)" :disabled="busy.renewPrice">
                    <b-spinner class="mr-2" small v-if="busy.renewPrice"></b-spinner>
                    가격 갱신
                  </b-button>

                  <b-button class="mr-1" size="sm" variant="danger" @click="deleteProcessing()" :disabled="busy.deleteProcessing">
                    <b-spinner class="mr-2" small v-if="busy.deleteProcessing"></b-spinner>
                    삭제
                  </b-button>
                  <b-button class="mr-1" size="sm" variant="danger" @click="toTrash()" :disabled="busy.toTrash">
                    <b-spinner class="mr-2" small v-if="busy.toTrash"></b-spinner>
                    Trash
                  </b-button>
                  <b-button class="" size="sm" variant="success" @click="sendProcessingModal()">송출</b-button>
                </div>
              </div>

              <hot-table data-key="processing" ref="hotTableProcessing" :settings="hotSettingProcessing"></hot-table>

              <b-button class="mr-1" size="sm" variant="outline-info" v-b-toggle.processingHelp>상품등록 기능 설명</b-button>
              <b-button size="sm" variant="outline-success" v-b-toggle.sendLog @click="sendLog">상품 송출 이력</b-button>
              <b-collapse id="processingHelp">
                <small>* goods_status 가 'processing' 인 상품들만 보여줍니다. 상품이 안나올 경우 등록상품조회 메뉴에서 검색해보세요.</small><br/>
                <small>* 저장 ~ 송출 등의 기능은 선택된 상품만을 대상으로 합니다.</small><br/>
                <small>* NaverSKU 창 : 이걸 눌러서 뜬 창을 옆 모니터에 두고 상품을 선택하면 SKU 로 자동검색이 됩니다.</small><br/>
                <small>* 이미지 갱신 : 이미지가 안뜨거나 원본 이미지가 바뀐 경우 갱신을 하면 새 이미지로 처리를 시작합니다.</small><br/>
                <small>* 가격 갱신 : 가격을 새로 갱신합니다.</small><br/>
                <small>* 삭제 : processing 에서 삭제하며 이미지 등도 삭제합니다. 다시 processing 으로 생성할 수 있습니다.</small><br/>
                <small>* Trash : trash 로 보내며 이미지 등도 삭제합니다. 해당 goods_id 는 영구적으로 제외됩니다. 앞으로 쓰지 않을 것이 확실한 상품을 trash 로 보내면 됩니다.</small>
                <small>* 송출 : 발란몰로 송출하며 노출상태 및 newin, sale 카테고리 편입을 선택할 수 있습니다.</small>
              </b-collapse>
              <b-collapse id="sendLog">
                <div class="title-sm clearfix">
                  상품 송출 이력
                </div>
                <template v-for="e in items.sendLog">
                  <a :class="`badge mr-2 alert-${e.ok ? 'success' : 'danger'}`" style="font-weight:normal"
                     :title="`${e._name} ${e._dt}${!e.ok ? ', ' + e.msg : ''}`"
                     @click="$modal.show({title:'JSON 보기', html:'<pre>' + JSON.stringify(e, null, 2) + '</pre>'})">
                    <template v-if="e.shop_ids">SHOP {{ e.shop_ids.join(', ') }}:</template>
                    {{ e.range }}, {{ e.goods_nos.length }} goods, {{ e.mode }}
                    <template v-if="e.newin">, NewIn</template>
                    <template v-if="e.sale">, Sale</template>
                    <i v-if="!e.ok" class="ml-1 fa fa-exclamation-circle"></i>
                  </a>
                </template>
              </b-collapse>
            </b-col>
            <b-col v-if="processingDetail" class="border-left" lg="4">
              <template v-if="item.goods_no">
                <div class="">
                  <a :href="`/#/shop/${item.shop_id}`" target="_blank" class="badge badge-success">{{ item.shop_id }}. {{ item.shop }}</a>
                  <b-badge variant="warning">{{ item.brand_nm }}</b-badge>
                  <b-badge variant="light" :title="item.category">{{ item.cate_name }}</b-badge>
                  <a :href="`/#/goods/${item.goods_no}`" class="badge badge-primary" target="_blank">{{ item.goods_no }}</a>
                </div>
                <div>
                  <a :href="`https://hub.balaan.io/r?u=${encodeURIComponent(item.crawl_url)}`" v-if="item.crawl_url && item.crawl_url.startsWith('http')"
                     target="_blank">{{ item._org.goods_nm }}</a>
                  <span v-else>{{ item._org.goods_nm }}</span>
                </div>
                <div>
                  <b-badge>원본상품명</b-badge>&nbsp;
                  <small><span v-html="item.org_name"></span></small>
                </div>
                <div>
                  <b-badge>원본카테고리</b-badge>&nbsp;
                  <small><span v-html="item.org_category"></span></small>
                </div>
                <div>
                  <b-badge>원본브랜드</b-badge>&nbsp;
                  <small><span v-html="item.org_brand_name"></span></small>
                </div>
                <div class="">
                  <b-badge>가격</b-badge>&nbsp;
                  <template v-if="item.consumer !== item.price">
                    <small>
                      <del>{{ utils.comma(item.consumer) }}</del>
                      원</small> →
                  </template>
                  <small>{{ utils.comma(item.price) }} 원 ({{ utils.rnc(item.org_price, 2) }} {{ item.org_price_unit }})</small>
                </div>
                <div class="">
                  <b-badge>옵션</b-badge>&nbsp;
                  <small><b>{{ item.options && item.options.length ? item.options[0].optnm : '' }}</b></small>&nbsp;
                  <template v-for="e in item.options.slice(0,7)">
                    <b-badge variant="light">{{ e.Size }}</b-badge>
                    <b-badge :variant="e.stock>0?'success':'secondary'" title="판매 가능수량">{{ e.stock }}</b-badge>
                  </template>
                </div>
                <small v-if="item.options.length > 7"> 외 {{ item.options.length - 7 }} 개 옵션</small>
                <div>
                  <b-badge>SKU ID</b-badge>&nbsp;
                  <small>{{ item.sku_id }}</small>&nbsp;
                  <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${item.sku_id}`" v-if="item._org.sku_id"
                     class="badge badge-success" target="_blank">네이버 쇼핑</a>
                </div>
                <div>
                  <b-badge>통합 SKU ID</b-badge>&nbsp;
                  <small>{{ item.matched_sku_id }}</small>&nbsp;
                  <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${item.matched_sku_id}`" v-if="item._org.sku_id"
                     class="badge badge-success" target="_blank">네이버 쇼핑</a>
                </div>
                <div>
                  <b-badge>등록일</b-badge>&nbsp;
                  <small><span v-html="item.created_dt"></span></small>
                </div>
                <!--<b-row>
                  <b-col>
                    <small class="text-muted mt-1">발란코드</small><br/>
                    <div>{{item.goods_no}}</div>
                  </b-col>
                  <b-col>
                    <small class="text-muted mt-1">브랜드</small><br/>
                    <div>{{item.brand_nm}}({{item.brand_nm_kr}})</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <small class="text-muted mt-1">카테고리</small><br/>
                    <div>{{item.category}}: {{item.cate_name}}</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <small class="text-muted mt-1">상품명</small><br/>
                    <div>
                      <a :href="`https://hub.balaan.io/r?u=${encodeURIComponent(item.crawl_url)}`" v-if="item.crawl_url && item.crawl_url.startsWith('http')" target="_blank">{{item._org.goods_nm}}</a>
                      <span v-else>{{item._org.goods_nm}}</span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <small class="text-muted mt-1">SKU ID</small>
                    <div>
                      {{item.sku_id}}
                      <a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${item.sku_id}`" v-if="item._org.sku_id" class="badge badge-success" target="_blank">네이버 쇼핑</a>
                    </div>
                  </b-col>
                  <b-col>
                    <small class="text-muted mt-1">등록일</small><br/>
                    <div v-html="item.created_dt"></div>
                  </b-col>
                </b-row>-->
                <b-badge>상세정보</b-badge>&nbsp;<br/>
                <div class="processing_desc" style="line-height: 1.1em;font-size: 12px;" v-html="item.description"></div>
                <b-badge variant="light" class="mb-1" @click="$modal.show({title: 'JSON 보기', item, type: 'json'})">JSON 보기</b-badge>
                <div class="d-flex flex-row processing_img">
                  <div class="d-flex flex-column" style="width:82px">
                    <small>원본이미지({{ item.original_img_urls.length }})</small>
                    <div v-for="i in item.original_img_urls">
                      <img :src="i" style="max-width:80px" @mouseover="item.img = i" @click="utils.open(i)"/>
                    </div>
                  </div>
                  <div class="d-flex flex-column" style="width:82px">
                    <small>이미지({{ item.img_urls.length }})</small>
                    <div v-for="(i, idx) in item.img_urls">
                      <img :src="$utils.getImageSrc(item, idx, 'thumb_200')" style="max-width:80px"
                           @mouseover="item.img = $utils.getImageSrc(item, idx, 'thumb_300')" @click="utils.open(i)"
                           @error="this.onerror = null; this.src = i"/>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-grow-1 flex-shrink-1">
                    <img v-if="item.img" class="w-100" :src="item.img"/>
                  </div>
                </div>
              </template>
              <div v-else class="text-center mt-5">
                상품을 선택해주세요
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <!--      <b-tab title="신규상품 등록">
              <b-row>
                <b-col lg="3">
                  <b-input-group>
                    <b-form-input type="text" v-model.number="formNew.goods_no" placeholder="기존 goods_no" @keypress.enter="getInfo"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="getInfo">기존상품 정보 가져오기</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
              <hr/>
              <cf-base ref="cfNew" :shop="shop" :category="category" :brand="brand" v-model="formNew.confirmed" @change="v=>formNew.confirmed=v"></cf-base>
              <b-button variant="success" @click="addProcessing">processing 에 추가하기</b-button>
              <b-button class="ml-1" variant="warning" @click="$refs.cfNew.reset()">초기화</b-button>
            </b-tab>-->
      <!--      <b-tab title="대량상품 등록">
              <div class="clearfix mb-1">
                <b-button class="mr-1" size="sm" variant="primary" @click="selectAllGoods('newGoods')">전체 선택/해제</b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="checkRegistered('all')">전체변경저장</b-button>
                <b-button class="mr-1" size="sm" variant="success" @click="checkRegistered">선택저장</b-button>
                <span>상품수 : {{items.newGoods.length}}</span>

                <div class="pull-right">
                  <b-button class="mr-1" size="sm" variant="info" @click="imageProcessingModal(items.newGoods)" :disabled="busy.renewImage">
                    <b-spinner class="mr-2" small v-if="busy.renewImage"></b-spinner>이미지 재처리</b-button>
                  <b-button class="mr-1" size="sm" variant="info" @click="renewPrice(items.newGoods)">가격 갱신</b-button>
                  <b-button class="mr-1" size="sm" variant="danger" @click="toTerminated(items.newGoods)">Terminate</b-button>
                </div>
              </div>
              <hot-table data-key="newGoods" ref="hotTableNewGoods" :settings="hotSettingNewGoods"></hot-table>
            </b-tab>-->
      <b-tab title="기존상품 관리">
        <tab-registered ref="tabRegistered" v-bind="{shop, category, categoryMap, down, handleXlsx}"></tab-registered>
      </b-tab>

      <b-tab title="개인결제창 등록">
        <b-row>
          <b-col lg="3">
            <b-input-group>
              <b-form-input type="text" v-model="formPersonal.name" placeholder="고객명" @keypress.enter="setPersonalInfo"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="setPersonalInfo">폼 입력</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <hr/>
        <b-row>
          <b-col lg="3">
            <b-input-group>
              <b-form-radio-group class="col-form-label" v-model="formPersonal.custom_type" :options="options.custom_type"></b-form-radio-group>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <small>상품명(goods_nm)</small>
            <b-input placeholder="ex) [BALAAN님 개인결제창 | 발란코드 : 1000000]" v-model="formPersonal.goods_nm"></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <small>원산지(origin)</small>
            <b-input :disabled="formPersonal.custom_type !== 'personal'" placeholder="ex) [BALAAN님 개인결제창]" v-model="formPersonal.origin"></b-input>
          </b-col>
          <b-col>
            <small>사이즈명(Size)</small>
            <b-input :disabled="formPersonal.custom_type !== 'personal'" :placeholder="formPersonal.custom_type === 'repair' ? '결제' : 'ex) XL'" v-model="formPersonal.Size"></b-input>
          </b-col>
          <b-col>
            <small>수량(stock)</small>
            <b-input placeholder="ex) 1" v-model.number="formPersonal.stock"></b-input>
          </b-col>
          <b-col>
            <small>가격(price)</small>
            <b-input placeholder="ex) 360000" v-model.number="formPersonal.price"></b-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <small>상품 상세설명(description)</small>
            <b-textarea :placeholder="formPersonal.custom_type !== 'personal' ? 'ex) 발란코드2233456에 대한 수선비 결제' :'ex) 롯데아이몰에서 구입한 발란코드 2233445, 3322110 에 대한 차액 재결제'"
                        rows="4"
                        v-model="formPersonal.description"></b-textarea>
          </b-col>
        </b-row>
        <b-button class="m-1" variant="success" @click="makeCustomGoods">생성하기</b-button>
        <b-button class="m-1" variant="warning" @click="resetFormPersonal">초기화</b-button>
        <div v-if="formPersonal.goods_no">
          <hr/>
          <a :href="`/#/goods/${formPersonal.goods_no}`" class="badge badge-primary" target="_blank">{{ formPersonal.goods_no }}</a> 상품이 생성되었습니다.&nbsp;
          <a :href="`${MALL_URL}/products/personal/${formPersonal.goods_no}`"
             target="_blank">발란몰 링크</a>&nbsp;
<!--          <a :href="`${MALL_URL}/products/personal/${formPersonal.goods_no}`"
             target="_blank">발란몰 모바일링크</a>-->
        </div>
      </b-tab>
    </b-tabs>

    <b-modal title="이미지 재처리" size="lg" v-model="modal.image" @ok="imageProcessing()">
      <b-alert show variant="info">총 {{ items.imageTarget.filter(e => e.selected).length }} 건의 상품의 이미지를 재처리합니다</b-alert>
      <ul>
        <li>기본 룰 : 크롬 확장프로그램 이미지 치환 -> 최초 상품 등록시의 원본 이미지 -> 현재의 원본 이미지 순으로 체크하여 먼저 발견된 이미지로 처리됩니다.</li>
        <li>최초 상품 등록시의 원본 이미지와 현재의 원본 이미지가 달라서 바뀐 이미지로 처리하려면 아래에서 선택해주세요</li>
        <li>원본 이미지의 순서와 현재 이미지의 순서가 다르다면 이미지 재처리 후 다시 바꿔줘야 합니다</li>
        <li>긴 이미지(SHOP 설정 참고)가 발견될 때, 상세설명의 이미지도 교체됩니다</li>
      </ul>
      <b-form-radio-group v-model="imageIntent" :options="[
        {text: '기본 룰', value: 'default'},
        {text: '크롬 이미지 치환 결과를 무시하고 최초 등록시의 원본 이미지로 재처리', value: 'useOriginal'},
        {text: '크롬 이미지 치환 결과를 무시하고 현재의 원본 이미지 재처리', value: 'useMapped'},
      ]" stacked>
      </b-form-radio-group>
    </b-modal>

    <b-modal title="Processing 상품 생성" size="lg" v-model="modal.processing" @ok="processGoods">
      <b-alert show variant="info">{{ targetGoodsCnt }} 건의 상품을 선택하셨습니다.</b-alert>
      <label>상품수 지정</label><br/>
      <b-form-input ref="targetGoodsLimit" v-model.number="targetGoodsLimit"></b-form-input>
    </b-modal>

    <b-modal title="Processing 상품 전송" size="lg" v-model="modal.send" @ok="sendProcessing">
      <b-alert show variant="info">{{ items.processing.filter(e => e.selected).length }} 개의 상품을 선택하셨습니다.</b-alert>
      <label>상품노출 지정</label><br/>
      <b-form-radio-group class="col-form-label" v-model="formSend.mode" :options="[
                {text: '노출', value: 'view'},
                {text: '미노출', value: 'notview'},
              ]">
      </b-form-radio-group>
      <b-checkbox v-model="formSend.newin">NewIn 카테고리에 추가합니다</b-checkbox>
      <b-checkbox v-model="formSend.sale">Sale 카테고리에 추가합니다</b-checkbox>
    </b-modal>

    <b-modal title="Processing 필드 선택" size="lg" v-model="modal.processingField" ok-only @ok="setField('processing')">
      <b-alert show variant="info">선택한 필드만 보이게 합니다.</b-alert>
      <b-form-checkbox-group v-model="processingField">
        <b-form-checkbox class="d-block" v-for="e in procColBase" :value="e">{{ e.name }} ({{ e.data }})</b-form-checkbox>
      </b-form-checkbox-group>
    </b-modal>
  </div>
</template>

<script>
import dateInput from '@/views/modules/DateInput.vue'
import tabRegistered from '@/views/goods/ProcessingTabRegistered.vue'
import {HotTable} from '@handsontable/vue';
import Handsontable from 'handsontable';
import {Switch as cSwitch} from '@coreui/vue'
import {getMeta, getJson, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {down, getHeaderRow, fixData, readXlsx} from '@/shared/impexp'
import {MALL_GOODS_LINK, ORDER_STATUS_CODE, CONFIRMED_COLUMNS} from 'balaan_constants'
import * as C from 'balaan_constants'
import * as momentBiz from 'moment-business-days';
import store from '@/store';
import {htImgRenderer, afterChangeGen, cfColumns} from '@/shared/ht_helper';

const {state: S, getters: {R}} = store;

const procColBase = utils.clone(cfColumns);
const regColBase = utils.clone(cfColumns);

export default {
  name: 'Processing',
  title: '상품등록 및 수정',
  components: {dateInput, HotTable, cSwitch, tabRegistered},
  data() {
    return {
      utils, MALL_GOODS_LINK, ORDER_STATUS_CODE, CONFIRMED_COLUMNS, momentBiz, S, R,
      procColBase, regColBase,
      statusCodeMap: {},
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      formNew: {
        goods_no: '',
        emptyCf: {
          goods_status: 'processing',
          display_status: 'view',
          stock_status: 'runout',
          options: [],
          img_urls: [],
          original_img_urls: [],
        },
        confirmed: {
          options: [],
          original_img_urls: []
        },
      },
      formClearance: {},
      formPersonal: {
        name: '',
        goods_nm: '',
        origin: '',
        Size: '',
        price: '',
        stock: 1,
        description: '',
        goods_no: null,
        is_dev: '',
        custom_type: 'personal'
      },
      formShop: {
        search: '',
        shopFilter: '',
        shopType: 'ALL',
        shop: [],
        brand: [],
        category: [],
        goods_id: '',
      },
      formProcessing: {
        shopFilter: '',
        shopType: 'ALL',
        search: '',
        shop: [],
        brand: [],
        category: [],
        goods_status: 'processing',
        stock_status: 'ALL',
        display_status: 'ALL',
        min: '',
        max: '',
        limit: 500,
        skip: 0,
        cateOver2nd: false,
        goods_no: '',
      },
      formSend: {
        mode: 'view',
        newin: false,
        sale: false,
      },
      formProcessLog: {
        _d: this.$moment().format('YYYY-MM-DD'),
      },
      lastForm: {},
      busy: {
        stat: true, shops: false, processing: false, processingMore: false, processLog: false,
        saveProcessing: false, renewImage: false, renewPrice: false, deleteProcessing: false, toTrash: false, sendProcessing: false,
        getUnmatchedMeta: false,
      },
      hasMore: {processing: false},
      collapse: {processing: false},
      processLogWithError: false,
      processingDetail: true,
      modal: {processing: false, send: false, processingField: false, image: false},

      targetGoodsCnt: 0,
      targetGoodsLimit: 0,
      targetGoodsType: '',

      tabIndex: 0,
      shopList: [],
      filteredShopList: [],
      items: {
        newGoods: [],
        processLog: [],
        processing: [],
        sendLog: [],
        // registered: [],
        imageTarget: [],
      },
      imageIntent: 'default',
      item: {},
      options: {
        custom_type: [
          {text: '개인 결제', value: 'personal'},
          {text: '수선비 결제', value: 'repair'},
        ],
      },
      unmatchedListItem: {},
      naverWindow: null,
      naverTimeout: null,
      naverSku: '',
      ac: {}, // abortController
      changed: {processing: 0},
      goodsCntBase: {
        hub: {total: 0, processing: 0, registered: 0, normal: 0, runout: 0, notview: 0, terminated: 0, trash: 0},
        godo: {total: 0, shop: {}, open: 0, closed: 0}, // shop: {shop_id:{open:0, closed:0}}
        imall: {total: 0, open: 0, closed: 0, terminated: 0},
        ss: {total: 0, open: 0, closed: 0},
        mapped: {total: 0, live: 0},
        _dt: '',
      },
      goodsCnt: {},

      checkModifiedRenderer(instance, td, row, col, prop, value, cellProperties) {
        let good = this.items[instance.rootElement.dataset.key][row];
        const escaped = Handsontable.helper.stringify(value);
        if (td && good._changed[prop]) {
          td.className = 'htCenter htMiddle bg-changed';
          td.innerText = escaped;
          // instance.setCellMeta(row, col, 'className', 'htCenter htMiddle bg-changed');
        } else {
          Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties);
        }
        return td;
      },
      /*hotSettingNewGoods: {
        data: [],
        colHeaders: (index) => {
          if (index === 0) {
            return `${this.items.registered.filter(e=>e.selected).length}/${this.items.registered.length}`;
          }
          // return ',원본이미지,이미지,상품정보,SHOP ID,SHOP,발란코드,가격,카테고리,브랜드,상품명,시즌,컬러,원산지,소재,measurements,modelsize'.split(',')[index];
          return ',원본상품,이미지,상품정보,상품명,시즌,컬러,원산지,소재,measurements,modelsize'.split(',')[index];
        },
        columns: [
          {data: 'selected', type: 'checkbox'},
          {data: 'base_goods_no'},
          {data: 'img_urls', readOnly: true, renderer: htImgRenderer},
          {
            data: 'goods_no', readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              let e = this.items.registered[cellProperties.row];
              let opt = e.options.filter(e=>!e.not_found);
              td.innerHTML = `<div class="mb-n1"><a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop}</a> <span class="badge badge-warning">${e.brand_nm}</span>`
                + ` <span class="badge badge-light" title="${e.category}">${e.cate_name}</span></div>`
                + `<div class="mb-n1"><a href="/#/goods/${e.goods_no}" class="badge badge-primary" target="_blank">${e.goods_no}</a> `
                + (e.consumer !== e.price ? `<small><del>${utils.comma(e.consumer)}</del> 원</small> →` : '')
                + ` <small>${utils.comma(e.price)} 원</small></div>`
                + `<small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> `
                + `${opt.slice(0,3).map(e=>
                  `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock>0?'success':'secondary'}" title="판매 가능수량">${e.stock}</span>`
                ).join(' ')}${opt.length > 3 ? `<small> 외 ${opt.length - 3} 개 옵션</small>` : ''}`;
            }
          },
          {data: 'goods_nm', renderer: (...p)=>this.checkModifiedRenderer(...p)},
          {data: 'season', renderer: (...p)=>this.checkModifiedRenderer(...p)},
          {data: 'color', renderer: (...p)=>this.checkModifiedRenderer(...p)},
          {data: 'origin', renderer: (...p)=>this.checkModifiedRenderer(...p)},
          {data: 'composition', renderer: (...p)=>this.checkModifiedRenderer(...p)},
          {data: 'measurements', renderer: (...p)=>this.checkModifiedRenderer(...p)},
          {data: 'modelsize', renderer: (...p)=>this.checkModifiedRenderer(...p)},
        ],
        className: "htCenter htMiddle",
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        colWidths: [80,80,80,380,300,70,100,100,200,150,150],
        columnSorting: true,
        height: 650,
        rowHeights: 60,
        afterChange: afterChangeGen('registered', 'hotTableNewGoods').bind(this),
        licenseKey: 'non-commercial-and-evaluation',
      },*/
      hotSettingsShops: {
        data: [],
        colHeaders: (index) => {
          if (index === 0) {
            return `${this.filteredShopList.filter(e => e.selected).length}/${this.filteredShopList.length}`;
          }
          return ',SHOP ID,SHOP 이름,새로운상품,판매가능상품,등록가능상품,완전매칭상품,확인시각,미매칭 보기'.split(',')[index];
        },
        // colHeaders: function (col) {
        //   let headers = ',SHOP ID,SHOP 이름,새로운상품,판매가능상품,등록가능상품,확인시각'.split(',');
        //   return headers[col];
        //   switch (col) {
        //     case 0:
        //       return `<input type="checkbox" class="checker" ${'checked'}>`;
        //     default:
        //       return headers[col];
        //   }
        // },
        // data: Handsontable.helper.createSpreadsheetData(6, 10),
        // colHeaders: ',SHOP ID,SHOP 이름,새로운 상품수,등록가능 상품수,확인시각'.split(','),
        columns: [
          {data: 'selected', type: 'checkbox'},
          ...'shop_id,shop_name,new_goods_cnt,sellable_goods_cnt,sendable_goods_cnt,perfect_goods_cnt,_dt'.split(',').map(e => {
            return {data: e, readOnly: true};
          }),
          {
            data: 'btn', readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              if (td) {
                td.className = 'htCenter htMiddle';
                td.innerText = '미매칭 보기'
              }
            }
          },
        ],
        className: "htCenter",
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        colWidths: [80, 70, 230, 90, 100, 100, 100, 150, 100],
        columnSorting: true,
        height: 500,
        afterSelectionEnd: (row, column, row2, column2, selectionLayerLevel, a, b) => {
          this.hotTableShopsLastRange = {row, column, row2, column2};

          // 여러 row 선택이면 skip
          if (row !== row2) return;
          // 맨 마지막 컬럼 선택일 때만
          if (column !== 8) return;

          let shop = this.$refs.hotTableShops.hotInstance.getDataAtRow(row); // [null, shop_id, shop_name]

          // 조회해서 unmatchedListItem 에 넣는다.
          this.busy.getUnmatchedMeta = true;
          getJson('/goods/getUnmatchedMeta?shop_id=' + shop[1]).then(j => {
            this.busy.getUnmatchedMeta = false;
            if (j) {
              this.unmatchedListItem = {shop_id: shop[1], shop_name: shop[2], ...j};
              this.$refs.unmatchedArea.style = `max-height:${this.$refs.shopArea.clientHeight}px; overflow-y:auto;`;
            }
          });
        },
        // rowHeaderWidth: 300,
        licenseKey: 'non-commercial-and-evaluation',
      },
      hotTableShopsLastRange: {},
      processingField: procColBase.filter(e => e.processing),
      hotSettingProcessing: {
        data: [],
        colHeaders: `선택,이미지,상품정보,GoodsId,상품명,${procColBase.filter(e => e.processing).map(e => e.name).join(',')}`.split(','),
        columns: [
          {data: 'selected', type: 'checkbox'},
          // {data: 'original_img_urls', readOnly: true, renderer: htImgRenderer},
          {data: 'img_urls', readOnly: true, renderer: htImgRenderer},
          {
            data: 'goods_no', readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              let e = this.items.processing[cellProperties.row];
              let opt = e.options.filter(e => !e.not_found);
              e.org_price = Math.min.apply(null, opt.map(e => e.org_price));
              td.innerHTML = `<div class="mb-n1"><a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop}</a> <span class="badge badge-warning">${e.brand_nm}</span>`
                + ` <span class="badge badge-light" title="${e.category}">${e.cate_name}</span></div>`
                + `<div class="mb-n1"><a href="/#/goods/${e.goods_no}?goods_status=ALL" class="badge badge-primary" target="_blank">${e.goods_no}</a> `
                + (e.consumer !== e.price ? `<small><del>${utils.comma(e.consumer)}</del> 원</small> →` : '')
                + ` <small>${utils.comma(e.price)} 원 (${utils.rnc(e.org_consumer, 2) + ' ' + e.org_price_unit})</small></div>`
                + `<small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small> `
                + `${opt.slice(0, 3).map(e =>
                  `<span class="badge badge-light">${e.Size}</span><span class="badge badge-${e.stock > 0 ? 'success' : 'secondary'}" title="판매 가능수량">${e.stock}</span>`
                ).join(' ')}${opt.length > 3 ? `<small> 외 ${opt.length - 3} 개 옵션</small>` : ''}`;
            }
          },
          // {data: 'shop_id', readOnly: true},
          // {data: 'shop', readOnly: true},
          // {data: 'goods_no', readOnly: true},
          // {data: 'price', readOnly: true},
          // {data: 'category', readOnly: true},
          // {data: 'brand_nm', readOnly: true},
          {data: 'goods_nm', renderer: (...p) => this.checkModifiedRenderer(...p)},
          {data: 'goods_id', readOnly: true},
          ...procColBase.filter(e => e.processing).map(e => ({...e, renderer: (...p) => this.checkModifiedRenderer(...p)})),
          // ...this.hotSettingColumns.filter(e=>~this.hotSettingProcessing.otherCols.indexOf(e.data)),
        ],
        className: "htCenter htMiddle",
        autoWrapCol: false,
        autoWrapRow: false,
        manualColumnResize: true,
        fixedColumnsLeft: 3,
        // colWidths: [80,80,80,320,70,180,80,100,150,150,300,70,100,100,200,150,150],
        colWidths: [80, 80, 320, 300, 120, ...procColBase.filter(e => e.processing).map(e => e.width)],
        columnSorting: true,
        height: 650,
        rowHeights: 60,
        afterSelectionEnd: (row, column, row2, column2, selectionLayerLevel) => {
          // 여러 row 선택이면 skip
          if (row !== row2) return this.item = {};
          this.item = this.items.processing[row];
          if (this.item.sku_id && this.naverSku !== this.item.sku_id) {
            this.naverSku = this.item.sku_id;
            if (this.naverWindow) {
              if (this.naverTimeout) clearTimeout(this.naverTimeout); // 연속 교체에 대비하기 위한
              this.naverTimeout = setTimeout(e => {
                this.naverWindow.location = 'https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=' + this.item.sku_id;
              }, 500);
            }
            // this.searchGoogle(this.item);
          }
        },
        afterChange: afterChangeGen('processing', 'hotTableProcessing').bind(this),
        // modifyData(a,b,c,d) {
        //   d === 'set' && console.log(a,b,c,d);
        // },
        licenseKey: 'non-commercial-and-evaluation',
      },
      registeredField: utils.clone(regColBase.filter(e => e.registered)),

    }
  },
  beforeMount() {
    this.goodsCnt = utils.clone(this.goodsCntBase);
  },
  watch: {
    tabIndex(v) {
      if (v === 2) {
        // setTimeout(e=>this.$refs.hotTableNewGoods.hotInstance.render(), 0);
      } else if (v === 0) {
        setTimeout(e => this.$refs.hotTableShops.hotInstance.render(), 0);
        setTimeout(e => this.$refs.hotTableProcessing.hotInstance.render(), 0);
      } else if (v === 1) {
        setTimeout(e => this.$refs.tabRegistered.$refs.hotTableRegistered.hotInstance.render(), 0);
      }
    },
    processingDetail() {
      setTimeout(e => this.$refs.hotTableProcessing.hotInstance.render(), 0);
    }
  },
  async created() {
    this.refreshStat();
    // hotTable 설정을 가져온다
    utils.getStatus('Processing', this, 'processingField');

    // 설정에서 필드를 복구한다.
    this.setField('processing');

    // temp1.hotInstance.getColWidth(4)
    let meta = await getMeta('shop,brand,category,holiday');
    if (!meta) return;

    this.shop = meta.shop.sort((a, b) => (a.use_yn === 'n' ? 10000 : 0) + a.shop_id - (b.use_yn === 'n' ? 10000 : 0) - b.shop_id);
    this.shop.forEach(s => {
      s.value = s.boutique;
      s.label = s.text = `${s.use_yn !== 'y' ? '[미사용] ' : ''}${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    this.brand = meta.brand.map(e => {
      return this.brandMap[e.brand_no] = {...e, value: e.brand_no, label: `${e.brand_nm} (${e.brand_nm_kr})`, text: `${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a, b) => a.label.localeCompare(b.label));

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, label: `${e.category} (${e.category_nm})`, text: `${e.category} (${e.category_nm})`};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));

    // formNew
    this.formNew.confirmed = utils.clone(this.formNew.emptyCf);

    // formShop
    // this.getNewGoodsId();
    // 상품등록 이력
    this.processLog();

    // formProcessing
    // this.listProcessing();

    this.sendLog();

    // Handsontable.Dom.addEvent(container, 'mousedown', function (event) {
    //   if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //     event.stopPropagation();
    //   }
    // });
    //
    // Handsontable.Dom.addEvent(container, 'mouseup', function (event) {
    //   if (event.target.nodeName == 'INPUT' && event.target.className == 'checker') {
    //     isChecked = !event.target.checked;
    //     hot2.render();
    //   }
    // });
  },
  mounted() {
    this.$refs.hotTableShops.hotInstance.loadData([{shop_name: 'Shop 선택 후 검색해주세요'}]);
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e => e).forEach(e => e.abort());
    utils.setStatus('Processing', this, 'processingField');
  },
  methods: {
    async refreshStat(nocache) {
      this.busy.stat = true;
      let j = await getJson('/goods/stat' + (nocache ? '?nocache=1' : ''));
      this.busy.stat = false;
      if (j) {
        this.goodsCnt = utils.clone(this.goodsCntBase);
        let {list, terminated, trash, godo, imall, ss, mapped, _t, _dt} = j;
        let proc = 0, normal = 0, runout = 0, notview = 0;
        list.forEach(e => {
          switch (e._id.goods) {
            case 'processing':
              this.goodsCnt.hub.processing += e.cnt;
              break;
            case 'terminated':
              this.goodsCnt.hub.terminated += e.cnt;
              break;
            case 'registered':
              if (e._id.stock === 'normal' && e._id.display === 'view') {
                this.goodsCnt.hub.normal += e.cnt;
              } else if (e._id.display !== 'view') {
                this.goodsCnt.hub.notview += e.cnt;
              } else if (e._id.stock !== 'normal') {
                this.goodsCnt.hub.runout += e.cnt;
              } else {
                console.log('???')
              }
              break;
            default:
              console.log('goods_status', e.goods);
          }
        });
        this.goodsCnt.hub.trash = trash;
        this.goodsCnt.hub.terminated += terminated;
        this.goodsCnt.hub.registered = this.goodsCnt.hub.normal + this.goodsCnt.hub.notview + this.goodsCnt.hub.runout;
        this.goodsCnt.hub.total = this.goodsCnt.hub.trash + this.goodsCnt.hub.terminated + this.goodsCnt.hub.processing + this.goodsCnt.hub.registered;
        godo.forEach(e => {
          let shop = this.goodsCnt.godo.shop[e.shop_id] = this.goodsCnt.godo.shop[e.shop_id] || {open: 0, closed: 0};
          if (e.open) {
            shop.open += e.cnt;
            this.goodsCnt.godo.open += e.cnt;
          } else {
            shop.closed += e.cnt;
            this.goodsCnt.godo.closed += e.cnt;
          }
        });
        this.goodsCnt.godo.total = this.goodsCnt.godo.open + this.goodsCnt.godo.closed;

        imall.forEach(e => {
          switch (e._id) {
            case '10':
              this.goodsCnt.imall.open = e.cnt;
              break;
            case '20':
              this.goodsCnt.imall.closed = e.cnt;
              break;
            case '30':
              this.goodsCnt.imall.terminated = e.cnt;
              break;
          }
        });
        this.goodsCnt.imall.total = this.goodsCnt.imall.open + this.goodsCnt.imall.closed + this.goodsCnt.imall.terminated;

        ss.forEach(e => {
          switch (e._id) {
            case 'SALE':
              this.goodsCnt.ss.open = e.cnt;
              break;
            case 'SUSP':
              this.goodsCnt.ss.closed = e.cnt;
              break;
          }
        });
        this.goodsCnt.ss.total = this.goodsCnt.ss.open + this.goodsCnt.ss.closed;

        this.goodsCnt.mapped = mapped;
        this.goodsCnt._dt = _dt;

        Object.entries({proc, normal, runout, notview, terminated, trash}).forEach(([k, v]) => {
          this[k] = v;
        });
      }
    },
    async getInfo() {
      let j = await postJson('/goods/confirmed', {goods_no_include: [this.formNew.goods_no]});
      if (j) {
        if (j.list.length === 0) {
          alert(`${this.formNew.goods_no} 상품이 존재하지 않습니다.`);
        } else {
          this.formNew.confirmed = utils.clone(this.formNew.emptyCf);
          'goods_no,brand_no,category,color,composition,crawl_url,description,display_status,goods_id,goods_nm,img_urls,measurements,modelsize,options,org_price_unit,origin,season,shop_id,sku_id,matched_sku_id,src_id,stock_status'.split(',').forEach(k => {
            this.formNew.confirmed[k] = j.list[0][k];
          });
          this.formNew.confirmed.original_img_urls = this.formNew.confirmed.img_urls;
          // this.formNew.confirmed.shop = this.shopMap[j.list[0].shop_id];
          this.$refs.cfNew.select({...this.formNew.confirmed}); // shop, brand, category set
        }
      }
    },
    // async addProcessing() {
    //   let cf = this.formNew.confirmed;
    //   // 필수요소 체크
    //   let required = 'goods_nm,shop_id,category,brand_no,origin,color,crawl_url'.split(',').filter(e=>!cf[e]);
    //   if (required.length) {
    //     return alert('아래 요소들은 필수요소입니다\n=================\n' + required.join('\n'));
    //   }
    //   if (cf.options.filter(e=>e.Size && e.stock > 0 && e.goods_price > 30000 && e.goods_consumer > 30000).length !== cf.options.length || cf.options.length === 0) {
    //     return alert('옵션이 없거나 값에 문제가 있는 옵션이 존재하는지 체크해주세요');
    //   }
    //   if (cf.original_img_urls.filter(e=>e).length === 0) {
    //     return alert('이미지는 하나 이상 존재해야 합니다');
    //   }
    //   // 선택요소 체크
    //   let need = 'goods_id,sku_id,matched_sku_id,season,measurements,modelsize,composition,description'.split(',').filter(e=>!cf[e]);
    //   if (need.length) {
    //     if (!confirm('아래 요소들 없이 진행하시겠습니까?\n=================\n' + need.join('\n'))) {
    //       return;
    //     }
    //   }
    //
    //   let j = await postJson('/goods/addProcessing', {cf: this.formNew.confirmed});
    //   if (j) {
    //     alert('processing 으로 등록되었습니다.');
    //     this.$refs.cfNew.reset();
    //   }
    // },
    async getNewGoodsId() {
      this.$refs.hotTableShops.hotInstance.loadData([{shop_name: '로딩중입니다'}]);

      let form = this.formShop;
      this.busy.shops = true;
      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e => e.value);
      let category = form.category.map(e => e.value);
      let goods_id = form.goods_id.trim().split(/\r?\n/g).map(e => e.trim()).filter(e => e);
      this.lastForm.shop = utils.clone({...form, shop, brand, category, goods_id});
      let j = await postJson('/goods/newGoodsId', {form: {...form, shop, brand, category, goods_id}});
      this.busy.shops = false;
      if (!j) return;
      let list = j.list;
      list.forEach(e => {
        if (this.shopMap[e.shop_id]) {
          e.shop_name = this.shopMap[e.shop_id].boutique;
          e.shop_type = this.shopMap[e.shop_id].shop_type;
        } else {
          e.shop_name = '(SHOP 을 찾지 못함)';
          e.shop_type = 'etc';
        }
        e.new_goods_cnt = e.new_goods_id.length;
        e.sellable_goods_cnt = e.sellable_goods_id ? e.sellable_goods_id.length : 0;
        e.sendable_goods_cnt = e.sendable_goods_id ? e.sendable_goods_id.length : 0;
        e.perfect_goods_cnt = e.perfect_goods_id ? e.perfect_goods_id.length : 0;
      });
      list.sort((a, b) => b.sendable_goods_cnt - a.sendable_goods_cnt);
      // list.sort((a, b) => b.perfect_goods_cnt - a.perfect_goods_cnt);
      this.shopList = list.filter(e => this.shopMap[e.shop_id] && this.shopMap[e.shop_id].use_yn === 'y');
      this.filterShop();
      // this.$refs.hotTableShops.hotInstance.loadData(this.filteredShopList);
    },
    filterShop() {
      this.$refs.hotTableShops && this.$refs.hotTableShops.hotInstance.loadData(this.filteredShopList = this.shopList.filter(e => (this.formShop.shopType === 'ALL' || e.shop_type === this.formShop.shopType) && (
        ~(e.shop_id + '').indexOf(this.formShop.shopFilter) || ~((e.shop_name || '').toLowerCase()).indexOf(this.formShop.shopFilter.toLowerCase()) || ~(e._dt).indexOf(this.formShop.shopFilter)
      )));
    },
    filterShopType(type) {
      this.$refs.hotTableShops && this.$refs.hotTableShops.hotInstance.loadData(this.filteredShopList = this.shopList.filter(e => (type === 'ALL' || e.shop_type === type) && (
        ~(e.shop_id + '').indexOf(this.formShop.shopFilter) || ~((e.shop_name || '').toLowerCase()).indexOf(this.formShop.shopFilter.toLowerCase()) || ~(e._dt).indexOf(this.formShop.shopFilter)
      )));
    },
    selectAllShop() {
      let selectAll = !this.filteredShopList.every(e => e.selected);
      this.filteredShopList.forEach(e => e.selected = selectAll);
    },
    async processAllGoods() {
      this.targetGoodsType = 'all';
      this.targetGoodsLimit = this.targetGoodsCnt = this.filteredShopList.filter(e => e.selected).map(e => e.new_goods_id.length).reduce((a, b) => a + b);
      if (this.targetGoodsCnt === 0) return utils.alert('등록 가능한 상품이 없습니다');
      this.modal.processing = true;
      setTimeout(_ => this.$refs.targetGoodsLimit.select(), 10);
    },
    async processSellableGoods() {
      this.targetGoodsType = 'sellable';
      this.targetGoodsLimit = this.targetGoodsCnt = this.filteredShopList.filter(e => e.selected).map(e => e.sellable_goods_cnt).reduce((a, b) => a + b);
      if (this.targetGoodsCnt === 0) return utils.alert('등록 가능한 상품이 없습니다');
      this.modal.processing = true;
      setTimeout(_ => this.$refs.targetGoodsLimit.select(), 10);
    },
    async processSendableGoods() {
      this.targetGoodsType = 'sendable';
      this.targetGoodsLimit = this.targetGoodsCnt = this.filteredShopList.filter(e => e.selected).map(e => e.sendable_goods_cnt).reduce((a, b) => a + b);
      if (this.targetGoodsCnt === 0) return utils.alert('등록 가능한 상품이 없습니다');
      this.modal.processing = true;
      setTimeout(_ => this.$refs.targetGoodsLimit.select(), 10);
    },
    async processPerfectGoods() {
      this.targetGoodsType = 'perfect';
      this.targetGoodsLimit = this.targetGoodsCnt = this.filteredShopList.filter(e => e.selected).map(e => e.perfect_goods_cnt).reduce((a, b) => a + b);
      if (this.targetGoodsCnt === 0) return utils.alert('등록 가능한 상품이 없습니다');
      this.modal.processing = true;
      setTimeout(_ => this.$refs.targetGoodsLimit.select(), 10);
    },
    async processGoods() {
      if (this.targetGoodsLimit <= 0 || this.targetGoodsLimit > this.targetGoodsCnt) return utils.alert(`등록할 상품수를 1 ~ ${this.targetGoodsCnt} 사이로 지정해주세요`);
      let limit = this.targetGoodsLimit, type = this.targetGoodsType;
      let goods = this.filteredShopList.filter(e => e.selected).map(e => {
        if (limit <= 0) return null;
        let goods_id = (type === 'perfect' ? e.perfect_goods_id : (type === 'sendable' ? e.sendable_goods_id : (type === 'sellable' ? e.sellable_goods_id : e.new_goods_id))).slice(0, limit);
        limit -= goods_id.length;
        return {shop_id: e.shop_id, goods_id};
      }).filter(e => e);
      let j = await postJson('/goods/processMapped', {goods, type, limit: this.targetGoodsLimit, form: this.lastForm.shop});
      if (j) {
        const {_ids, inserted, errCnt, errorMap} = j;
        alert(`${j.found} 개의 상품 중 ${j.inserted} 개의 상품이 processing 상태로 등록되었습니다.` + (errCnt ? `\n${errCnt} 개의 에러가 발생했습니다.\n${Object.entries(errorMap).map(([k, v]) => `${k}: ${v}`).join(', ')}` : ''));
        // inserted 가 있으면 상품등록이력 갱신
        if (inserted) this.processLog(_ids);
      }
    },
    setProcessLogDay(d) {
      if (typeof d === 'number') {
        this.formProcessLog._d = this.$moment(this.formProcessLog._d, 'YYYY-MM-DD').add(d, 'day').format('YYYY-MM-DD');
      } else {
        this.formProcessLog._d = d;
      }
      this.processLog();
    },
    async processLog(_ids) {
      this.busy.processLog = true;
      let j = await getJson(`/goods/processLog?_d=${this.formProcessLog._d}`);
      this.busy.processLog = false;
      if (j) {
        this.items.processLog = j.list;
        if (_ids) {
          let goods_nos = j.list.filter(e => _ids.includes(e._id)).map(e => e.goods_nos).reduce((a, b) => a.concat(b), []);
          this.searchProcessing(goods_nos);
        }
      }
    },
    processLogFailDetail(obj) {
      return Object.entries(obj || {}).map(([k, v]) => `${k}: ${typeof v === 'number' ? v : v.length}`).join('\n');
    },
    async sendLog() {
      let j = await getJson('/goods/sendLog');
      if (j) {
        j.list.forEach(e => {
          e.range = e.goods_nos.length === 1 ? e.goods_nos[0] : `${Math.min.apply(null, e.goods_nos)} ~ ${Math.max.apply(null, e.goods_nos)}`;
        });
        this.items.sendLog = j.list;
      }
    },
    async searchProcessing(goods_nos) {
      this.formProcessing.goods_no = goods_nos.join(' ');
      if (goods_nos.length > this.formProcessing.limit) this.formProcessing.limit = goods_nos.length;
      this.collapse.processing = true;
      this.listProcessing();
    },
    async listProcessing(more) {
      let form;
      if (more === true) {
        if (this.busy.processingMore) return; // 이전요청을 기다린다
        form = this.lastForm.processing;
        form.skip += form.limit;
        this.busy.processingMore = true;
      } else {
        form = this.formProcessing;
        this.lastForm.processing = utils.clone(form);
        this.items.processing.splice(0, this.items.processing.length);
        this.busy.processing = true;
      }

      let shop = form.shop.length === this.shop.length ? [] : form.shop.map(e => e.shop_id); // 전체 선택일 경우 비우기
      let brand = form.brand.map(e => e.value);
      let category = form.category.map(e => e.value);
      let goods_no = this.formProcessing.goods_no.trim() ? this.formProcessing.goods_no.trim().split(/\D+/g).map(e => +e) : [];

      if (this.ac.processing) this.ac.processing.abort();
      this.ac.processing = new AbortController();
      let j;
      try {
        j = await postJson('/goods/confirmed', {...form, shop, brand, category, goods_no_include: goods_no}, {signal: this.ac.processing.signal});
        if (j) {
          j.list = j.list.filter(e => this.shopMap[e.shop_id].use_yn === 'y');
          j.list.forEach(e => {
            e._org = utils.clone(e);
            e._changed = {};
            e.img = e.img_urls ? (e.img_urls[0] || '') : '';
            if (!e.category || e.category.length % 3 !== 0) { // #N/A 등 이상한 값인 경우
              e.cate_name = e.category;
            } else {
              e.cate_name = e.category ? Array(e.category.length / 3).fill(0).map((_, i) => this.categoryMap[e.category.substring(0, 3 + i * 3)]).filter(e => e).map(e => e.category_nm).join(' > ') : '';
            }
            e.selected = false;
          });
        }
        this.items.processing = j.list;
      } catch (e) {
        console.error(e);
        if (more !== true) {
          this.items.processing = [];
          this.busy.processing = false;
        } else {
          this.busy.processingMore = false;
        }
        return;
      } finally {
        this.ac.processing = null;
      }

      this.hasMore.processing = j.hasMore;
      if (more === true) {
        this.items.processing = this.items.processing.concat(j.list);
        this.busy.processingMore = false;
      } else {
        this.items.processing = j.list;
        this.busy.processing = false;
        this.changed.processing = 0;
        this.item = {};
      }
      this.$refs.hotTableProcessing.hotInstance.loadData(this.items.processing);
    },
    async searchGoogle(item) {
      let j = await (await fetch(`https://www.googleapis.com/customsearch/v1/siterestrict?q=${item.sku_id}&cx=008780709072524291341%3Al6vdoeov6w0&key=AIzaSyBIOoAMqHumeTejx4gBwYcpCCFPY_cnUNE`)).json();
      /*
       kind: "customsearch#result"
       title: "AKIBA-HOBBY Rakuten: East star lotus ship - Undefined Fantastic ..."
       htmlTitle: "AKIBA-HOBBY Rakuten: East star lotus ship - <b>Undefined</b> Fantastic ..."
       link: "https://global.rakuten.com/en/store/akbh/item/2100000051946/"
       displayLink: "global.rakuten.com"
       snippet: "AKIBA-HOBBY Rakuten: East star lotus ship - Undefined Fantastic Object / ↵Shanghai Alice 幻樂団 - Purchase now to accumulate reedemable points! | ↵Rakuten ..."
       htmlSnippet: "AKIBA-HOBBY Rakuten: East star lotus ship - <b>Undefined</b> Fantastic Object / <br>↵Shanghai Alice 幻樂団 - Purchase now to accumulate reedemable points! | <br>↵Rakuten&nbsp;..."
       cacheId: "4fNuocnS5k0J"
       formattedUrl: "https://global.rakuten.com/en/store/akbh/item/2100000051946/"
       htmlFormattedUrl: "https://global.rakuten.com/en/store/akbh/item/2100000051946/"
       pagemap.cse_thumbnail[0].src, width, height
       */
      j.items.forEach(e => e.img = e.pageMap.cse_thumbnail[0].src);
      j.googleResult = {items: j.items};
    },
    selectAllGoods(key) {
      let selectAll = !this.items[key].every(e => e.selected);
      this.items[key].forEach(e => e.selected = selectAll);
    },
    openPopup() {
      this.naverWindow = utils.open('https://shopping.naver.com/', 'naver_sku');
    },
    async saveProcessing(target) {
      if (target === 'all') {
        this.items.processing.forEach(e => e.selected = !!(e._modified && !Object.values(e._changed).every(e => !e)));
      }
      let selected = this.items.processing.filter(e => e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      let modified = selected.filter(e => e._modified);
      if (!confirm(`선택한 ${selected.length} 개의 상품중 ${modified.length} 개가 수정되었습니다. 저장하시겠습니까?`)) return;
      let goods = modified.map(e => {
        let obj = {goods_no: e.goods_no};
        Object.keys(e._changed).forEach(key => {
          obj[key] = e[key];
        });
        return obj;
      });
      this.busy.saveProcessing = true;
      let j = await postJson('/goods/saveProcessing', {goods});
      this.busy.saveProcessing = true;
      if (j && j.ok === 1) {
        if (j.warnings.length) {
          let body = j.warnings.map(e => `<span class="badge badge-light">${e.goods_no}</span> - ${e.warning || ''}`).join('<br/>');
          this.$modal.show({title: '업로드 이슈 확인', html: `<pre>${body}</pre><br/><br/>${j.cnt} 건 저장되었습니다.`});
        } else {
          utils.alert(`저장되었습니다.`);
        }
        let noMap = utils.arr2map(goods, 'goods_no');
        this.items.processing.filter(e => noMap[e.goods_no]).forEach(row => {
          let good = noMap[row.goods_no];
          Object.keys(row).filter(k => good[k]).forEach(k => {
            row[k] = good[k];
            row._org[k] = good[k];
          }); // row에 있는 key만 덮어쓴다
          row._changed = {};
          row._modified = false;
        });
      } else if (j && j.ok === -1) {
        let body = `<h4>${j.msg}</h4>` + j.errors.map(e => `<span class="badge badge-light">${e.goods_no}</span> - ${e.error || ''}`).join('<br/>');
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + body + '</pre>'});
      }
      this.busy.saveProcessing = false;
    },
    async undoProcessing() {
      let selected = this.items.processing.filter(e => e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      let modified = selected.filter(e => e._modified);
      if (!confirm(`선택한 ${selected.length} 개의 상품중 ${modified.length} 개가 수정되었습니다. 수정을 취소하고 원래 값으로 되돌리시겠습니까?`)) return;
      modified.forEach(e => {
        Object.keys(e._changed).forEach(key => {
          e[key] = e._org[key];
        });
        e._modified = false;
      });
      this.changed.processing = this.items.processing.filter(e => e._modified).length;
    },
    async imageProcessingModal(items) {
      let selected = items.filter(e => e.selected).map(e => ({goods_no: e.goods_no, shop_id: e.shop_id, img_urls: e.original_img_urls}));
      if (!selected.length) return alert('이미지를 재처리할 상품을 선택해주세요');
      this.items.imageTarget = items;
      this.modal.image = true;
    },
    async imageProcessing() {
      this.busy.renewImage = true;
      let j = await postJson('/goods/requestImageProcessing', {
        goods_nos: this.items.imageTarget.filter(e => e.selected).map(e => e.goods_no),
        intent: this.imageIntent
      });
      this.busy.renewImage = false;
      if (j) {
        alert(`${j.cnt} 개 상품에 대해 이미지 작업이 시작되었습니다.` + (j.errors.length ? `\n${j.errors.length} 개의 에러가 발생했습니다:\n${j.errors.map(e => e.error).join('\n')}` : ''));
        this.modal.image = false;
      }
    },
    async renewPrice(items) {
      let selected = items.filter(e => e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      this.busy.renewPrice = true;
      let j = await postJson('/goods/renewPrice', {goods_nos: selected.map(e => e.goods_no)});
      this.busy.renewPrice = false;
      if (j) {
        utils.alert(`${selected.length} 개의 상품의 가격이 갱신되었습니다.`);
        selected.forEach(e => {
          let cf = j.cfMap[e.goods_no];
          e.consumer = cf.consumer;
          e.price = cf.price;
          e.supply = cf.supply;
        });
        this.$refs.hotTableProcessing.hotInstance.render();
      }
    },
    async deleteProcessing() {
      let selected = this.items.processing.filter(e => e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      if (!confirm(`${selected.length} 개의 processing 상품을 정말로 삭제하시겠습니까?`)) return;
      this.busy.deleteProcessing = true;
      let j = await postJson('/goods/deleteProcessing', {goods_nos: selected.map(e => e.goods_no)});
      this.busy.deleteProcessing = false;
      if (j) {
        utils.alert(`${selected.length} 개의 상품이 삭제되었습니다.`);
        this.items.processing = this.items.processing.filter(e => !~selected.indexOf(e));
        this.$refs.hotTableProcessing.hotInstance.loadData(this.items.processing); // 재정의된 array를 할당하지 않으면 데이터 상이가 일어난다.
      }
    },
    async toTrash() {
      let selected = this.items.processing.filter(e => e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      if (!confirm(`${selected.length} 개의 processing 상품을 정말로 Trash 로 보내시겠습니까? Trash 로 보내면 이후 해당 goods_id 의 상품은 무시되어 숨게지게 됩니다.`)) return;
      this.busy.toTrash = true;
      let j = await postJson('/goods/toTrash', {goods_nos: selected.map(e => e.goods_no)});
      this.busy.toTrash = false;
      if (j) {
        utils.alert(`${selected.length} 개의 상품이 Trash 로 보내졌습니다.`);
        this.items.processing = this.items.processing.filter(e => !~selected.indexOf(e));
        this.$refs.hotTableProcessing.hotInstance.loadData(this.items.processing); // 재정의된 array를 할당하지 않으면 데이터 상이가 일어난다.
      }
    },
    async sendProcessingModal() {
      let selected = this.items.processing.filter(e => e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      this.modal.send = true;
    },
    async sendProcessing() {
      let selected = this.items.processing.filter(e => e.selected);
      let selectedNo = this.items.processing.filter(e => e.selected).map(e => e.goods_no);
      this.busy.sendProcessing = true;
      let j = await postJson('/goods/sendProcessing', {goods_nos: selectedNo, ...this.formSend});
      this.busy.sendProcessing = false;
      if (j) {
        // selected.forEach(e=>{
        //   let idx = this.items.processing.indexOf(e);
        //   this.items.processing.splice(idx, 1);
        // }); // 수 초 이상 다운될 정도로 느리다
        this.items.processing = this.items.processing.filter(e => !~selectedNo.indexOf(e.goods_no));
        this.$refs.hotTableProcessing.hotInstance.loadData(this.items.processing); // 재정의된 array를 할당하지 않으면 데이터 상이가 일어난다.
        // this.$refs.hotTableProcessing.hotInstance.render();
        utils.alert(`${selected.length} 개의 상품이 발란몰로 전송되었습니다.`);
        this.sendLog();
      }
    },
    async toTerminated(items) {
      let selected = items.filter(e => e.selected);
      if (selected.length === 0) return alert('상품을 선택해 주시기 바랍니다.');
      let selectedNo = items.filter(e => e.selected).map(e => e.goods_no);
      utils.alert(selectedNo.join());
    },

    resetFormPersonal() {
      'name,goods_nm,origin,Size,price,description'.split(',').forEach(e => this.formPersonal[e] = '');
      this.formPersonal.stock = 1;
    },
    async makeCustomGoods() {
      let form = this.formPersonal;
      // 필수요소 체크
      let required = 'goods_nm,origin,Size,stock,price';
      if (form.custom_type === 'repair') {
        form.Size = '결제';
        form.origin = '[BALAAN님 개인결제창]'
      }
      required = required.split(',').filter(e => !form[e]);

      if (required.length) {
        return alert('아래 요소들은 필수요소입니다\n=================\n' + required.join('\n'));
      }

      if (!confirm(`${form.goods_nm} 상품을 등록하시겠습니까?`)) return;

      let j = await postJson('/goods/customGoods', {form});
      if (j) {
        alert('등록되었습니다.');
        this.resetFormPersonal();
        form.goods_no = j.goods_no;
        form.is_dev = j.is_dev;
      }
    },
    setPersonalInfo() {
      if (!this.formPersonal.name) return;
      this.formPersonal.goods_nm = `[${this.formPersonal.name}님 개인결제창 | 발란코드 : 1000000]`;
      this.formPersonal.origin = `[${this.formPersonal.name}님 개인결제창]`;
    },
    setField(target) {
      // colBase 객체들로 순서에 맞게 다시 구성한다
      this.processingField = procColBase.filter(e => ~this.processingField.map(e => e.data).indexOf(e.data));
      this.registeredField = regColBase.filter(e => ~this.registeredField.map(e => e.data).indexOf(e.data));

      if (target === 'processing') {
        let settings = {
          colHeaders: `선택,이미지,상품정보,상품명,GoodsId,${this.processingField.map(e => e.name).join(',')}`.split(','),
          columns: [...this.hotSettingProcessing.columns.slice(0, 5), ...this.processingField.map(e => ({
            ...e,
            renderer: (...p) => this.checkModifiedRenderer(...p)
          }))],
          colWidths: [...this.hotSettingProcessing.colWidths.slice(0, 5), ...this.processingField.map(e => e.width)],
        };
        if (this.$refs.hotTableProcessing) {
          this.$refs.hotTableProcessing.hotInstance.updateSettings(settings);
        } else {
          this.hotSettingProcessing = {...this.hotSettingProcessing, ...settings};
        }
      } else if (target === 'registered') {
        let settings = {
          colHeaders: `선택,이미지,상품정보,상품명,오늘도착여부,${this.registeredField.map(e => e.name).join(',')}`.split(','),
          columns: [...this.hotSettingRegistered.columns.slice(0, 5), ...this.registeredField.map(e => ({
            ...e,
            renderer: (...p) => this.checkModifiedRenderer(...p)
          }))],
          colWidths: [...this.hotSettingRegistered.colWidths.slice(0, 5), ...this.registeredField.map(e => e.width)],
        };
        if (this.$refs.hotTableRegistered) {
          this.$refs.hotTableRegistered.hotInstance.updateSettings(settings);
        } else {
          this.hotSettingRegistered = {...this.hotSettingRegistered, ...settings};
        }
      }
      utils.setStatus('Processing', this, 'processingField,registeredField');
    },

    async down(items, type, prefix) {
      // 다양한 데이터를 다양한 구성으로 다양한 타입으로 다운받는다.
      // mapped 데이터를 추가한다.
      let selected = items.filter(e => e.selected);
      if (!selected.length) return alert('다운받을 상품을 선택해주세요');
      let keys = ('goods_no,goods_nm,category,category_nm,brand_nm,brand_nm_kr,brand_no,sku_id,matched_sku_id,delivery_type,oneday_delivery,' +
        'today_pick,today_pick_time,store_pickup,gift_packing,hide_es,mng_code,' +
        'origin,season,color,description,composition,measurements,modelsize,seller_name,optnm,md_name,shop,shop_id,goods_id,product_id,' +
        'category_code,consumer,price,discount_rate,tot_stock,crawl_url,stock_status,goods_status,display_status,created_dt,confirmed_dt,updated_dt'
      ).split(',')
        , values = keys.map(e => CONFIRMED_COLUMNS[e]);
      let name = values.map(e => e.name);

      // mapped 컬럼 추가
      let entries = Object.entries(C.MAPPED_COLUMNS).filter(([k, v]) => k !== 'options' && (!v.group || v.group === 'optmerge'));
      keys = keys.concat(entries.map(([k, v]) => `mapped_${k}`));
      values = values.concat(entries.map(([k, v]) => v));
      name = name.concat(entries.map(e => e[1].name));

      // mapped 데이터 추가
      let j = await postJson('/goods/mapped', {goods_id: selected.map(e => e.goods_id).join(','), _projection: utils.arr2map(entries.map(e => e[0]))});
      if (j) {
        let mpMap = utils.arr2map(j.list, 'shop_id,goods_id');
        selected.forEach(e => {
          let mp = mpMap[`${e.shop_id}|${e.goods_id}`];
          if (mp) {
            entries.forEach(([k, v]) => {
              e[`mapped_${k}`] = mp[k];
            });
          } else {
            console.log(e);
          }
        });
      }

      let code = keys, codeMap = {};
      keys.forEach(e => codeMap[e] = e);
      let rows = [codeMap, ...selected];
      down(rows, name, code, `${prefix || 'Confirmed'}_${utils.dt()}`, type);
    },
    async handleXlsx(event) {
      let file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return utils.alert('xlsx 파일을 업로드해주세요');
      let {headers, rows} = await readXlsx(file); // 이 시점에서 값이 없는 field 는 누락될 수 있다.
      this.upload(event.target, headers, rows);
    },
    async upload(target, headers, rows) {
      /**
       업로드 전 내용을 체크한다. confirmed 를 기준으로 한다.
       1. 컬럼들이 올바른가
       2. 컬럼들 데이터 형식이 올바른가
       3. 상태변경이 올바른가 (서버)
       */
      let keys = ('goods_no,goods_nm,category,category_nm,brand_nm,brand_nm_kr,brand_no,sku_id,matched_sku_id,' +
        'delivery_type,oneday_delivery,today_pick,today_pick_time,origin,season,color,description,composition,measurements,modelsize,' +
        'seller_name,optnm,md_name,shop,shop_id,goods_id,product_id,category_code,consumer,price,discount_rate,tot_stock,crawl_url,' +
        'stock_status,goods_status,display_status,created_dt,confirmed_dt,updated_dt').split(',');

      let keyRow = rows[0];
      let keyNameMap = {};
      headers.forEach(e => keyNameMap[keyRow[e]] = e);
      rows.splice(0, 1);

      // {발란코드:199999} 를 {goods_no:1999999} 로 바꾼다.
      // mapped_ 컬럼들은 제거한다
      // 위의 handleXlsx 에서 누락된 값들의 경우 undefined 가 될 수 있다.
      rows = rows.map(row => {
        let obj = {};
        headers.filter(e => !keyRow[e].startsWith('mapped_')).forEach(e => obj[keyRow[e]] = row[e] == null ? '' : row[e]);
        return obj;
      });

      // keys 가 전부 존재해야 한다. -> 기준을 완화한다.
      // let notFoundCols = keys.filter(e=>!keyNameMap[e]);
      // if (notFoundCols.length) {
      //   return alert(`없는 컬럼이 존재합니다 : ${notFoundCols.join(', ')}`);
      // }
      // if (headers.length !== keys.length) {
      //   return alert(`컬럼수가 일치하지 않습니다. ${keys.length} 개로 구성되어야 합니다.`);
      // }

      let wrongRows = [];
      rows.forEach((row, i) => {
        let wrongCols = [];
        keys.forEach(key => {
          let tester = CONFIRMED_COLUMNS[key].test;
          if (row[key] != null && row[key] !== '' && tester && !tester.test(row[key])) {
            wrongCols.push(`${key}: ${row[key]}`);
          }
          if ((row[key] == null || row[key] === '') && CONFIRMED_COLUMNS[key].required) {
            wrongCols.push(`${key}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx: i, cols: wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n' + wrongRows.map(e => `${e.idx + 2} 번째줄 ${e.cols.map(e => e).join(', ')}`).join('\n'));

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      let typeHeaders = keys.filter(key => CONFIRMED_COLUMNS[key].type);
      rows.forEach(row => {
        typeHeaders.forEach(key => {
          if (key === 'today_pick_time' && row[key] && typeof row[key] === 'number') { // 12:00 을 엑셀에 바로 입력하면 0.5 등으로 24시간 대비 값으로 입력되어 들어온다.
            const hours = row.today_pick_time * 24;
            row.today_pick_time = `${Math.floor(hours)}:${Math.floor((hours % 1) * 60).toString().padStart(2, '0')}`;
          }
          let type = CONFIRMED_COLUMNS[key].type;
          if (type === 'number') {
            row[key] = +row[key];
          } else if (type === 'string') {
            row[key] = '' + (row[key] == null ? '' : row[key]);
          }
        });
      });

      let type = target.dataset.type;
      let j = await postJson('/goods/uploadGoods', {type, goods: rows});
      if (j && j.ok === 1) {
        if (j.warnings.length) {
          let body = j.warnings.map(e => `<span class="badge badge-light">${e.goods_no}</span> - ${e.warning || ''}`).join('<br/>');
          this.$modal.show({title: '업로드 이슈 확인', html: `<pre>${body}</pre><br/><br/>${j.cnt} 건 업로드 되었습니다.`});
        } else {
          utils.alert(`${j.cnt} 건 업로드 되었습니다.`);
        }
        let noMap = utils.arr2map(rows, 'goods_no');
        let items = [];
        if (type === 'processing') {
          items = this.items.processing;
          // this.listProcessing();
        } else if (type === 'registered') {
          items = this.$refs.tabRegistered.items.registered;
          // this.listRegistered();
        }
        items.filter(e => noMap[e.goods_no]).forEach(row => {
          let good = noMap[row.goods_no];
          Object.keys(row).filter(k => good[k]).forEach(k => {
            row[k] = good[k];
            row._org[k] = good[k];
          }); // row에 있는 key만 덮어쓴다
          row._changed = {};
          row._modified = false;
        });
      } else if (j && j.ok === -1) {
        let body = `<h4>${j.msg}</h4>` + j.errors.map(e => `<span class="badge badge-light">${e.goods_no}</span> - ${e.error || ''}`).join('<br/>');
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + body + '</pre>'});
      }
      target.value = "";
    },
    copyTsv(type) {
      let tsv = '';
      if (type === 'brands') {
        tsv = this.unmatchedListItem.brands.map(e => `${e.brand}\t${e.cnt}`).join('\n');
      } else if (type === 'cates') {
        tsv = this.unmatchedListItem.cates.map(e => `${e.category}\t${e.origin_category}\t${e.cnt}`).join('\n');
      }
      this.$utils.copyToClipboard(tsv);
      this.$alertTop(`복사되었습니다`);
    },
    copyGoodsId() {
      let {row, column, row2, column2} = this.hotTableShopsLastRange;
      if (row == null) return alert('복사할 GoodsId 위치를 선택해주세요');
      if (row !== row2) return alert('복사할 GoodsId 위치를 하나만 선택해주세요');
      if (column !== column2) return alert('복사할 GoodsId 위치를 하나만 선택해주세요');
      if (column < 3 || column > 6) return alert('복사할 GoodsId 위치를 새로운상품, 판매가능상품, 등록가능상품, 완전매칭상품 중에서 선택해주세요');
      let data = this.$refs.hotTableShops.hotInstance.getDataAtRow(row); // [null, shop_id, shop_name]
      let shop = this.filteredShopList.filter(e => e.shop_id === data[1])[0];
      let goods_ids = shop[['new_goods_id', 'sellable_goods_id', 'sendable_goods_id', 'perfect_goods_id'][column - 3]];
      this.$utils.copyToClipboard(goods_ids.join('\n') || ' ');
      this.$alertTop(`복사되었습니다`);
    },
    async imageNotReady() {
      let j = await getJson('/goods/imageNotReady');
      if (j) {
        const html = [];
        j.list.forEach(e => {
          const shop = this.shopMap[e._id];
          if (shop) {
            html.push(`${shop.text}, ${shop.sync_origin}, ${shop.fetch_origin} : ${e.cnt}`);
          } else {
            html.push(`${e._id} : ${e.cnt}`);
          }
        });
        this.$modal.show({title: '이미지 미처리 상품수', html: html.join('<br/>')});
      }
    }
  }
}
</script>

<style>
@import '~handsontable/dist/handsontable.full.css';

.processing_img img {
  border: 1px solid #eee;
}

.processing_desc {
  word-break: break-word;
}

.processing_desc img {
  width: 100%;
  max-width: 300px;
}
</style>
